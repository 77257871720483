.home-bk {
  background-image: url(assets/sky-scrapers.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  filter: brightness(0.9);
  position: fixed;
}
.home-bk::before {
  content: '';
  background: rgba(255, 255, 255, 0.1); /* Adjust color and transparency */
  backdrop-filter: blur(5px); /* Adjust the blur amount */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.home-bk-dark {
  background-image: url(assets/neon-dark.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  filter: brightness(1.5);
  position: fixed;
}

.home-bk-dark::before {
  content: '';
  background: rgba(255, 255, 255, 0.05); /* Adjust color and transparency */
  backdrop-filter: blur(5px); /* Adjust the blur amount */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

pre {
  background: #000000;
  padding: 5px 10px;
  border-radius: 5px;
  color: #d0a1ff;
  display: inline-flex;
  width: 100%;
  overflow: auto;
}
